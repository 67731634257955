import React, { useEffect } from "react";
import heroBg from "../assets/images/brandingHeroBg.jpg";
import HeroImage from "../components/HeroImage";
import CustomBranding from "../components/CustomBranding";
import CustomPackages from "../components/CustomPackages";
import Portfolio from "../components/Portfolio";
import Testimonial from "../components/Testimonial";
import ContactUsForm from "../components/ContactUsForm";
import icon from "../assets/images/packagesIcon2.svg";
import hassanAlKhalifaImg from "../assets/images/Testomonial Images/BrandingPage/hassanAlKhalifa.svg";
import khalidAbbasImg from "../assets/images/Testomonial Images/BrandingPage/khalidAbbas.svg";
import laylaAhmedImg from "../assets/images/Testomonial Images/BrandingPage/laylaAhmed.svg";
import DynamicSeo from "../utils/DynamicSeo.js";

const BrandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // ----Hero Data ----
  const headingOne = (
    <>
      Ignite Your Brand's Presence<span className="text-white"> with </span>
    </>
  );
  const headingTwo = "Our Top-Tier Logo Design Services";
  const description =
    "Looking for a striking new logo, or a complete rebrand? Capitalize on our logo design services.";

  // ---Branding Data---
  const brandingData = [
    {
      image: require("../assets/images/customBranding2.png"),
      title: "Logo Design Services",
      description: (
        <>
          Our logo design services craft distinct, memorable logos that
          encapsulate your brand's essence and resonate with your target
          audience.Let MME be your ally.
          {/* <span className="d-lg-block"> Let MME be your ally.</span> */}
        </>
      ),
    },
    {
      image: require("../assets/images/customBranding1.png"),
      title: "Branding Services",
      description: (
        <>
          Our comprehensive branding services build a cohesive and powerful
          brand identity that drives recognition and build loyalty.Count on MME
          for rapid growth
          {/* <span className="d-lg-block">Count on MME for rapid growth.</span> */}
        </>
      ),
    },
  ];

  // -----Portfolio Data-----
  const imageData = [
    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/1.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/2.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/3.png"),
    },

    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/6.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/4.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/5.png"),
    },
  ];

  const BrandingImageData = [
    {
      src: require("../assets/images/Portfolio Images/Branding/1.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/Branding/2.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/Branding/3.png"),
    },

    {
      src: require("../assets/images/Portfolio Images/Branding/4.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/Branding/5.jpg"),
    },
    {
      src: require("../assets/images/Portfolio Images/Branding/6.png"),
    },
  ];

  const packagesHeading = "Branding and";
  const packagesHeadingTwo = "Logo Packages";
  const packagesDescription = (
    <>
      Soar to success with our comprehensive branding and logo packages,
      <span className="d-lg-block">
        designed to create a powerful and lasting impression.
      </span>
    </>
  );

  const packagesData = [
    {
      title: "Starter Package",
      icon: icon,
      price: "10.000BD",
      features: [
        {
          title: "Basic Logo Design",
          details: [
            "Simple and modern design suitable for startups or personal brands.",
          ],
        },
        {
          title: "Revisions",
          details: ["Up to 3 rounds of revisions."],
        },
        {
          title: "File Formats",
          details: ["Includes JPEG and PNG formats."],
        },
        {
          title: "Delivery Time",
          details: ["3-5 business days."],
        },
      ],
    },

    {
      title: "Professional Package",
      icon: icon,
      price: "35.000BD",
      features: [
        {
          title: "Custom Logo Design",
          details: [
            "Tailored and professional design to establish a strong brand identity.",
          ],
        },
        {
          title: "Revisions",
          details: ["4-6 rounds of revisions for precise adjustments."],
        },
        {
          title: "File Formats",
          details: [
            "Includes vector files (AI, EPS) and raster files (JPEG, PNG).",
          ],
        },

        {
          title: "Delivery Time",
          details: ["5-7 business days."],
        },
      ],
    },

    {
      title: "Executive Package",
      icon: icon,
      price: "50.000BD",
      features: [
        {
          title: "Bespoke Logo Design:",
          details: [
            "Highly creative and unique logo design for established businesses.",
          ],
        },
        {
          title: "Revisions:",
          details: ["Unlimited revisions to ensure perfection."],
        },
        {
          title: "File Formats",
          details: [
            "Comprehensive files including vector (AI, EPS, SVG) and raster (JPEG, PNG).",
          ],
        },

        {
          title: "Brand Style Guide",
          details: [
            "Detailed guidelines covering color palette, typography, and logo usage.",
          ],
        },
        {
          title: "Social Media Kit",
          details: ["Customized profile and cover images for major platforms."],
        },
        {
          title: "Priority Support",
          details: ["Dedicated designer and support team."],
        },
        {
          title: "Delivery Time",
          details: ["7-10 business days."],
        },
      ],
    },
  ];

  const testimonialData = [
    {
      description:
        "Modern Media Expert exceeded our expectations with their branding expertise. They crafted a logo that perfectly encapsulates our company's vision and values. Their attention to detail and creative approach set them apart. Highly recommend their services!",
      userName: "Hassan Al Khalifa",
      userRole: "CEO, GulfTech Innovations",
      icon: hassanAlKhalifaImg,
    },
    {
      description:
        "We entrusted MME with our branding needs, and they delivered exceptional results. Their strategic insights and innovative design concepts helped us establish a strong brand presence in the tech industry. Their expertise in logo design is truly commendable.",
      userName: "Khalid Abbas,",
      userRole: "Founder, TechWave Solutions ",
      icon: khalidAbbasImg,
    },

    {
      description:
        "Recently we have partnered with MME for our rebranding & truly it was a game-changer. They designed a modern and sophisticated brand identity that resonates with our luxury hospitality brand. Their professionalism and commitment to quality are unparalleled.",
      userName: "Layla Ahmed",
      userRole: "Marketing Director, Oasis Hotels Bahrain",
      icon: laylaAhmedImg,
    },
  ];

  const testimonialSubHeading =
    "Discover why our clients rave about our services see how we've helped businesses thrive online.";

  const brandingDesignSubHeading =
    "At MME, we craft designs that not only captivate but also drive your brand's growth, ensuring you connect deeply with your audience and stand out from the competition.";

  const heroBtnsTxtOne = "Let’s Connect";

  const heroBtnsTxtTwo = "Book a Free Consultation";
  return (
    <div>
      <DynamicSeo
        title="Unique Logo Design Secrets: Energize Your Brand Identity"
        description="Unlock unique logo design secrets that can energize your brand. Learn how to stand out with innovative designs that truly reflect your vision!"
      />
      <HeroImage
        heroBtnsTxtOne={heroBtnsTxtOne}
        heroBtnsTxtTwo={heroBtnsTxtTwo}
        heroBg={heroBg}
        headingOne={headingOne}
        headingTwo={headingTwo}
        description={description}
      />
      <div className="branding-page">
        <CustomBranding
          brandingData={brandingData}
          brandingDesignSubHeading={brandingDesignSubHeading}
        />

        <CustomPackages
          packagesHeading={packagesHeading}
          packagesHeadingTwo={packagesHeadingTwo}
          packagesDescription={packagesDescription}
          packagesData={packagesData}
        />
        <Portfolio
          imageData={imageData}
          BrandingImageData={BrandingImageData}
        />
        <Testimonial
          testimonialData={testimonialData}
          testimonialSubHeading={testimonialSubHeading}
        />
        <ContactUsForm />
      </div>
    </div>
  );
};

export default BrandingPage;
