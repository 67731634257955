import React, { useEffect } from "react";
import heroBg from "../assets/images/webDesignHeroBg.png";
import HeroImage from "../components/HeroImage";
import CustomBranding from "../components/CustomBranding";
import CustomPackages from "../components/CustomPackages";
import Portfolio from "../components/Portfolio";
import Testimonial from "../components/Testimonial";
import ContactUsForm from "../components/ContactUsForm";
import icon from "../assets/images/packagesIcon2.svg";
import ahmedAliImg from "../assets/images/Testomonial Images/WebDevelopmentPage/ahmedAli.svg";
import fatimaAliImg from "../assets/images/Testomonial Images/WebDevelopmentPage/fatimaAli.svg";
import saraFaisalImg from "../assets/images/Testomonial Images/WebDevelopmentPage/saraFaisal.svg";
import DynamicSeo from "../utils/DynamicSeo.js";

const WebDevelopmentPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // ----Hero Data ----
  const headingOne = (
    <>
      Build Exceptional Websites <span className="text-white">& </span>
    </>
  );
  const headingTwo = "Acquire Huge Success with MME";
  const description =
    "Our web development and design services include custom website development, responsive web design, UI/UX, & WordPress development.";

  // ---Branding Data---
  const brandingData = [
    {
      image: require("../assets/images/UiUx.png"),
      title: "Web Design / UI/UX",
      description: (
        <>
          At Modern Media Expert, we go beyond conventional design to create
          captivating and immersive
          <span className="d-lg-block">
            user interfaces that make your brand unforgettable.{" "}
          </span>
        </>
      ),
    },
    {
      image: require("../assets/images/cutomDevelopment.png"),
      title: "Custom Development",
      description: (
        <>
          Our custom development services offer cutting-edge, personalized
          solutions that break the mold
          <span className="d-lg-block">
            and drive your business to new heights.
          </span>
        </>
      ),
    },
    {
      image: require("../assets/images/wordPressDevelopment.png"),
      title: "WordPress Development",
      description: (
        <>
          We specialize in creating dynamic, high-performance WordPress websites
          that will facilitate your brand
          <span className="d-lg-block">
            and deliver exceptional user experiences.
          </span>
        </>
      ),
    },
  ];

  // -----Portfolio Data-----
  const WebsitesImageData = [
    {
      src: require("../assets/images/Portfolio Images/Websites/1.jpg"),
    },
    {
      src: require("../assets/images/Portfolio Images/Websites/2.jpg"),
    },
    {
      src: require("../assets/images/Portfolio Images/Websites/3.jpg"),
    },

    {
      src: require("../assets/images/Portfolio Images/Websites/4.jpg"),
    },
    {
      src: require("../assets/images/Portfolio Images/Websites/5.jpg"),
    },
    {
      src: require("../assets/images/Portfolio Images/Websites/6.jpg"),
    },
  ];

  const packagesHeading = "Web Design &";
  const packagesHeadingTwo = "Development Packages";
  const packagesDescription = (
    <>
      Experience Unmatched Success with our bespoke, user-focused web design &
      development
      <span className="d-lg-block">
        packages that blend creativity with state-of-the-art solutions.
      </span>
    </>
  );

  const packagesData = [
    {
      title: "Starter Web Presence Package",
      icon: icon,
      price: "149.000BD",
      features: [
        {
          title: "Professional Web Design",
          details: ["Modern UI/UX design tailored to your brand."],
        },
        {
          title: "Basic Custom Development",
          details: ["Entry-level custom features to enhance functionality."],
        },
        {
          title: "Basic WordPress Setup",
          details: ["Installation and basic customization."],
        },
        {
          title: "Delivery Time",
          details: ["7-10 business days."],
        },
      ],
    },

    {
      title: "Advanced Web Growth Package",
      icon: icon,
      price: "499.000BD",
      features: [
        {
          title: "Premium Web Design",
          details: ["Advanced UI/UX design for optimal user engagement."],
        },
        {
          title: "Custom Development",
          details: [
            "Advanced features and functionalities tailored to business needs.",
          ],
        },
        {
          title: "WordPress Customization",
          details: ["Tailored themes and plugins for enhanced functionality."],
        },

        {
          title: "SEO Integration",
          details: ["Basic on-page SEO for improved visibility."],
        },
        {
          title: "Delivery Time",
          details: ["10-15 business days."],
        },
      ],
    },

    {
      title: "Executive Web Excellence Package",
      icon: icon,
      price: "699.000BD",
      features: [
        {
          title: "Bespoke Web Design",
          details: ["Unique and sophisticated UI/UX design."],
        },
        {
          title: "Custom Development",
          details: [
            "Complex functionalities and integrations as per specifications.",
          ],
        },
        {
          title: "Advanced WordPress Customizatio",
          details: ["Comprehensive themes and plugins"],
        },

        {
          title: "E-commerce Integration",
          details: ["Full-scale e-commerce setup and optimization."],
        },
        {
          title: "SEO Optimization",
          details: ["Advanced on-page and technical SEO."],
        },
        {
          title: "Dedicated Support",
          details: ["Priority access to dedicated development team."],
        },
        {
          title: "Delivery Time",
          details: [
            "Custom timelines tailored to each service, averaging 15-20 business days.",
          ],
        },
      ],
    },
  ];

  const webDesignDevelopmentSubHeading =
    "We create user-friendly web designs paired with tailored development solutions. We combine aesthetics with functionality to develop an intuitive website.";

  const testimonialData = [
    {
      description:
        "Modern Media Expert revamped our e-commerce website, enhancing both functionality and aesthetics. Their design team crafted a visually stunning and intuitive platform that has increased our online sales and customer engagement. They are a reliable partner for web development.",
      userName: "Fatima Ali",
      userRole: "Marketing Manager, Oasis Fashion",
      icon: fatimaAliImg,
    },
    {
      description:
        "Working with MME on our website redesign was a breeze. They implemented cutting-edge technologies and responsive design that align perfectly with our sustainability-focused brand. Their dedication to delivering high-quality web solutions is impressive.",
      userName: "Ahmed Khalid,",
      userRole: "IT Manager, Green Energy Solutions ",
      icon: ahmedAliImg,
    },

    {
      description:
        "We were on the lookout for a futuristic agency who could assist us in transforming our website into a user-friendly platform. We choose MME. Their attention to UX/UI design & seamless development process significantly boosted our online bookings.",
      userName: "Sara Faisal",
      userRole: "COO, Blue Horizon Travel",
      icon: saraFaisalImg,
    },
  ];

  const testimonialSubHeading =
    "Explore glowing testimonials from clients who've experienced exceptional results and unwavering support.";

  const heroBtnsTxtOne = "Let’s Collaborate";

  const heroBtnsTxtTwo = "Schedule a Free Session";
  return (
    <div>
      <DynamicSeo
        title="The Ultimate UI UX Web Design Tips for Happy Users"
        description="Learn the ultimate tips for UI UX web design that ensure happy users and optimal user experiences. Start transforming your design today!"
      />
      <HeroImage
        heroBtnsTxtOne={heroBtnsTxtOne}
        heroBtnsTxtTwo={heroBtnsTxtTwo}
        heroBg={heroBg}
        headingOne={headingOne}
        headingTwo={headingTwo}
        description={description}
      />

      <div className="development-page">
        <CustomBranding
          brandingData={brandingData}
          webDesignDevelopmentSubHeading={webDesignDevelopmentSubHeading}
        />

        <CustomPackages
          packagesHeading={packagesHeading}
          packagesHeadingTwo={packagesHeadingTwo}
          packagesDescription={packagesDescription}
          packagesData={packagesData}
        />
        <Portfolio WebsitesImageData={WebsitesImageData} />
        <Testimonial
          testimonialData={testimonialData}
          testimonialSubHeading={testimonialSubHeading}
        />
        <ContactUsForm />
      </div>
    </div>
  );
};

export default WebDevelopmentPage;
