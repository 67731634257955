import React from "react";
import HeroImage from "../components/HeroImage";
import ExplainerVideo from "../components/ExplainerVideo";
import CustomServices from "../components/CustomServices";
import AboutUs from "../components/AboutUs";
import CustomPackages from "../components/CustomPackages";
import Portfolio from "../components/Portfolio";
import ContactUsForm from "../components/ContactUsForm";
import Testimonial from "../components/Testimonial";
import heroBg from "../assets/images/homeHeroBg.jpg";
import arrowIcon from "../assets/images/serciceArrowBlack.svg";
import icon from "../assets/images/packagesIcon2.svg";
import SarahMitchellImg from "../assets/images/Testomonial Images/HomePage/SarahMitchell.svg";
import MarkThomsonImg from "../assets/images/Testomonial Images/HomePage/MarkThomson.svg";
import EmilyRobertsImg from "../assets/images/Testomonial Images/HomePage/EmilyRoberts.svg";
import DynamicSeo from "../utils/DynamicSeo.js";

const HomePage = () => {
  // ----Hero Data ----
  const headingOne = (
    <>
      Create Indigenous Products<span className="text-white"> &</span>
    </>
  );
  const headingTwo = "Attain Massive Success with MME";
  const description =
    "At MME, we offer exclusive solutions for all sizes of businesses, ensuring your brand drives success.";

  // -----Portfolio Data-----
  const imageData = [
    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/1.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/2.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/3.png"),
    },

    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/6.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/4.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/LogoDesigns/5.png"),
    },
  ];

  const BrandingImageData = [
    {
      src: require("../assets/images/Portfolio Images/Branding/1.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/Branding/2.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/Branding/3.png"),
    },

    {
      src: require("../assets/images/Portfolio Images/Branding/4.png"),
    },
    {
      src: require("../assets/images/Portfolio Images/Branding/5.jpg"),
    },
    {
      src: require("../assets/images/Portfolio Images/Branding/6.png"),
    },
  ];

  const WebsitesImageData = [
    {
      src: require("../assets/images/Portfolio Images/Websites/1.jpg"),
    },
    {
      src: require("../assets/images/Portfolio Images/Websites/2.jpg"),
    },
    {
      src: require("../assets/images/Portfolio Images/Websites/3.jpg"),
    },

    {
      src: require("../assets/images/Portfolio Images/Websites/4.jpg"),
    },
    {
      src: require("../assets/images/Portfolio Images/Websites/5.jpg"),
    },
    {
      src: require("../assets/images/Portfolio Images/Websites/6.jpg"),
    },
  ];

  // ----Custom Sertvices Data----
  const servicesHeadingOne = "Our Premium";
  const servicesHeadingTwo = "Services";
  const serviceContent = (
    <>
      With 4+ years of experience in the industry, you can expect cutting-edge
      <span className="d-lg-block">
        design & development services at affordable rates.
      </span>
    </>
  );

  const servicesData = [
    {
      serviceImage: require("../assets/images/homeService1.png"),
      serviceTitle: (
        <>
          Logos & Branding
          <span className="visibility-hidden d-lg-block">Hidden</span>
        </>
      ),
      serviceDescription:
        "We create compelling brand identities that resonate. From taglines to full corporate overhauls, our work makes a lasting impression.",
      arrowIcon: arrowIcon,
      link: "/branding-and-design/",
    },

    {
      serviceImage: require("../assets/images/homeService2.png"),
      serviceTitle: (
        <>
          Web Design <span className="d-lg-block">& Development</span>
        </>
      ),
      serviceDescription:
        "Whether it's a static, dynamic, or e-commerce site, we tailor each project accordingly that best showcases your brand identity.",
      arrowIcon: arrowIcon,
      link: "/web-design-and-development/",
    },

    {
      serviceImage: require("../assets/images/homeService3.png"),
      serviceTitle: (
        <>
          Digital Marketing{" "}
          <span className="visibility-hidden d-lg-block">Hidden</span>
        </>
      ),
      serviceDescription:
        "We help businesses in enhancing brand awareness through tailored campaigns designed to achieve tangible results.",
      arrowIcon: arrowIcon,
      link: "/marketing/",
    },
  ];

  // ----Packages Data----

  const packagesHeading = "Our Exclusive";
  const packagesHeadingTwo = "Packages";
  const packagesDescription = (
    <div>
      Discover our tailored packages for various business needs, ensuring each
      <span className="d-lg-block">
        client receives a solution that aligns with their brand goals and
        budget.
      </span>
    </div>
  );

  const packagesData = [
    {
      title: "Starter Package",
      icon: icon,
      price: "10.000 BD",
      features: [
        {
          title: "Essential Logo Design",
          details: [
            "Sophisticated & modern design perfect for startups",
            "1-3 rounds of revisions",
          ],
        },
        {
          title: "File Formats",
          details: ["JPEG and PNG formats"],
        },
        {
          title: "Basic Website Design",
          details: [
            "Static design with up to 5 pages",
            "Responsive Design",
            "Ensures usability on mobile devices",
          ],
        },
        {
          title: "Social Media Setup",
          details: ["Creation of profiles on major platforms."],
        },
        {
          title: "Delivery Time",
          details: ["5-7 Business Days"],
        },
      ],
    },

    {
      title: "Business Pro",
      icon: icon,
      price: "50.000BD",
      features: [
        {
          title: "Custom Logo Design",
          details: [
            "Unique and professional design tailored for growing businesses",
            "4-6 rounds of revisions",
          ],
        },
        {
          title: "File Formats",
          details: [
            "Includes vector files (AI, EPS) and raster files (JPEG, PNG).",
          ],
        },
        {
          title: "Advanced Website Development",
          details: ["Dynamic website with CMS integration."],
        },

        {
          title: "SEO Optimization",
          details: ["Basic on-page SEO setup."],
        },
        {
          title: "Social Media Marketing",
          details: ["Initial setup and content strategy for two platforms."],
        },
        {
          title: "Delivery Time",
          details: ["10-14 Business Days"],
        },
      ],
    },

    {
      title: "Executive Growth",
      icon: icon,
      price: "100.000BD",
      features: [
        {
          title: "Bespoke Logo Design",
          details: [
            "Highly creative and distinctive logo design.",
            "Unlimited revisions.",
          ],
        },
        {
          title: "Comprehensive File Formats",
          details: [
            "Vector files (AI, EPS, SVG) and raster files (JPEG, PNG).",
          ],
        },
        {
          title: "Premium Website Development",
          details: [
            "Customized website with advanced features and e-commerce capability.",
          ],
        },

        {
          title: "Full-service Digital Marketing",
          details: ["SEO, PPC campaigns, social media management."],
        },
        {
          title: "Brand Strategy Consultation",
          details: [
            "Detailed brand guidelines and strategic planning session.",
          ],
        },
        {
          title: "Priority Support",
          details: ["Dedicated Designer and Marketing Specialist."],
        },
        {
          title: "Delivery Time",
          details: ["14-21 Business Days"],
        },
      ],
    },
  ];

  const testimonialSubHeading =
    "Discover Why We Are a Preferred Choice of Our Esteemed Clients ";

  const testimonialData = [
    {
      description:
        "Working with Modern Media Experts has been a game-changer for our brand. Their team designed a stunning logo that perfectly captures our company's spirit, and the website they developed has significantly increased our online engagement.",
      userName: "Sarah Mitchell,",
      userRole: "CEO, Innovative Tech Solutions",
      icon: SarahMitchellImg,
    },
    {
      description:
        "Their SEO and social media marketing efforts have significantly improved our online visibility. Their digital marketing strategies have also driven more traffic to our site, resulting in a noticeable boost in our sales. We couldn't be happier with the results!.",
      userName: "Mark Thompson,",
      userRole: "Founder, Green Earth Organics",
      icon: MarkThomsonImg,
    },

    {
      description:
        "From the initial consultation to the final delivery, MME has been outstanding. Their team is professional, responsive, and incredibly talented. We are thrilled with the results and look forward to continuing our partnership.",
      userName: "Emily Roberts",
      userRole: "Marketing Director, Urban Style Interiors",
      icon: EmilyRobertsImg,
    },
  ];

  const heroBtnsTxtOne = "Let’s Collaborate";

  const heroBtnsTxtTwo = "Set up a Free Consultation";

  return (
    <div>
      <DynamicSeo
        title="Modern Media Experts: The Ultimate Guide for Growth"
        description="Dive into the ultimate guide on modern media experts, packed with insights, tips, and strategies to enhance your media presence and achieve growth."
      />
      <HeroImage
        heroBtnsTxtOne={heroBtnsTxtOne}
        heroBtnsTxtTwo={heroBtnsTxtTwo}
        heroBg={heroBg}
        headingOne={headingOne}
        headingTwo={headingTwo}
        description={description}
      />
      <div className="home-page">
        <ExplainerVideo />
        <AboutUs />
        <CustomServices
          servicesHeadingOne={servicesHeadingOne}
          servicesHeadingTwo={servicesHeadingTwo}
          serviceContent={serviceContent}
          servicesData={servicesData}
        />
        <CustomPackages
          packagesHeading={packagesHeading}
          packagesHeadingTwo={packagesHeadingTwo}
          packagesDescription={packagesDescription}
          packagesData={packagesData}
        />
        <Portfolio
          imageData={imageData}
          BrandingImageData={BrandingImageData}
          WebsitesImageData={WebsitesImageData}
        />
        <Testimonial
          testimonialData={testimonialData}
          testimonialSubHeading={testimonialSubHeading}
        />
        <ContactUsForm />
      </div>
    </div>
  );
};

export default HomePage;
